'use client'

import { useReportWebVitals } from 'next/web-vitals'

import * as gtag from '@lib/gtag'

export default function WebVitals(): null {
  useReportWebVitals((metric) => {
    if (gtag) {
      gtag.event(metric.name, {
        event_category: metric.label === 'web-vital' ? 'Web Vitals' : 'Next.js custom metric',
        value: Math.round(metric.name === 'CLS' ? metric.value * 1000 : metric.value), // values must be integers
        event_label: metric.id, // id unique to current page load
        non_interaction: true, // avoids affecting bounce rate.
      })
    }
  })

  return null
}
