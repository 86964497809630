/* eslint-disable import/prefer-default-export */
import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type IsLoadingStore = {
  isLoading: boolean
  setIsLoading: (value: boolean, message?: string) => void
  message: string
}

export const useIsLoading = create<IsLoadingStore>()(
  devtools((set) => ({
    isLoading: false,
    message: 'Loading',
    setIsLoading: (isLoading, message): void => set({ isLoading, message: message ?? 'Loading' }),
  })),
)
