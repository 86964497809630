import { useUserStore } from '@stores/user.store'

import { useMemo } from 'react'

import { v4 as uuidGenerator } from 'uuid'

import { readLocalStorageValue, setLocalStorageValue } from '@lib/helpers/client'

export default function useGetUserID(): string {
  const profile = useUserStore((state) => state.profile)
  return useMemo(() => {
    if (profile && profile.user) {
      return profile.user.id
    }
    if (typeof window !== 'undefined') {
      let uuid = readLocalStorageValue('sonicUuid')
      if (uuid) {
        return uuid
      }
      uuid = uuidGenerator()
      if (!setLocalStorageValue('sonicUuid', uuid)) {
        return ''
      }
      return uuid
    }
    return ''
  }, [profile])
}
