'use client'

import { useUserStore } from '@stores/user.store'

import { useMemo } from 'react'

import useLocale from '@hooks/client/useLocale'

export default function useIsLoggedIn(): { isLoggedIn: boolean; isGuestLoggedIn: boolean; isUserLoggedIn: boolean } {
  const locale = useLocale()
  const [profile, tokens] = useUserStore((state) => [state.profile, state.tokens])

  const isLoggedIn = useMemo(() => {
    return Boolean(tokens[`${locale}-sonic-refresh`]) && Boolean(profile?.user?.id)
  }, [profile?.user?.id, locale, tokens])

  const isGuestLoggedIn = useMemo(
    () => isLoggedIn && (profile?.user?.isGuestUser ?? false),
    [isLoggedIn, profile.user?.isGuestUser],
  )

  return { isLoggedIn, isGuestLoggedIn, isUserLoggedIn: isLoggedIn && !isGuestLoggedIn }
}
