'use client'

import { useIsLoading } from '@stores/isLoading.store'

import { useEffect } from 'react'

import { usePathname, useSearchParams } from 'next/navigation'

export default function NavigationEvents(): null {
  const pathname = usePathname()
  const searchParams = useSearchParams()
  const setIsLoading = useIsLoading((state) => state.setIsLoading)

  useEffect(() => {
    // const url = `${pathname}?${searchParams}`
    window.document.scrollingElement?.scrollTo(0, 0)
    setIsLoading(false)
  }, [pathname, searchParams, setIsLoading])

  return null
}
