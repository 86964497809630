'use client'

import { useIsLoading } from '@stores/isLoading.store'

import Image from 'next/image'

import logo from './logo.png'

import styles from './loading-page.module.scss'

export default function LoadingPage(): JSX.Element {
  const isLoading = useIsLoading((state) => state.isLoading)
  const message = useIsLoading((state) => state.message)

  if (isLoading) {
    return (
      <div className={styles['loading-page']}>
        <div>
          <Image className={styles.logo} src={logo} alt="Logo" />
          <h2 className={styles.text}>
            {message}
            <span className={styles.dots}>
              <span className={styles.dot}>.</span>
              <span className={styles.dot}>.</span>
              <span className={styles.dot}>.</span>
            </span>
          </h2>
        </div>
      </div>
    )
  }
  return <></>
}
