export default function readLocalStorageValue(field: string): string | null {
  if (typeof window !== 'undefined') {
    try {
      return window.localStorage.getItem(field)
    } catch {
      return null
    }
  }
  return null
}
