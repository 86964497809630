/* eslint-disable import/prefer-default-export */
import type { CommunityProfile, User, UserSource } from '@types'

import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

type ProfileData = {
  user?: User
  community?: CommunityProfile
}

type UserStore = {
  source: UserSource
  setSource: (source: UserSource) => void
  profile: ProfileData
  setProfile: (profile: ProfileData) => void
  // Auto Generated Account
  isAGA: boolean
  setIsAGA: (val: boolean) => void
  tokens: Record<string, string>
  setTokens: (tokens: Record<string, string>) => void
}

export const useUserStore = create<UserStore>()(
  devtools((set) => ({
    source: '',
    setSource: (source): void => set({ source }),
    profile: {},
    setProfile: (profile): void => set({ profile }),
    isAGA: false,
    setIsAGA: (isAGA): void => set({ isAGA }),
    tokens: {},
    setTokens: (tokens): void => set((state) => ({ tokens: { ...state.tokens, ...tokens } })),
  })),
)
