'use client'

import { useUserStore } from '@stores/user.store'

import { startTransition, useCallback } from 'react'

import type { BackendHttpClientSideOptions, BackendService, FetchType, RestResponse } from '@types'

import { setCookie } from 'nookies'
import { useShallow } from 'zustand/react/shallow'

import { basePath } from '@lib/constants'
import { baseFetch } from '@lib/http'

import useLocale from './client/useLocale'
import useInnerLogout from './user/useInnerLogout'

import useHttpHeaders from './useHttpHeaders'

export type HttpMethods = {
  get: <JSON>(path: string, options?: BackendHttpClientSideOptions) => Promise<JSON | undefined>
  post: <JSON>(path: string, options?: BackendHttpClientSideOptions) => Promise<JSON | undefined>
  put: <JSON>(path: string, options?: BackendHttpClientSideOptions) => Promise<JSON | undefined>
  remove: <JSON>(path: string, options?: BackendHttpClientSideOptions) => Promise<JSON | undefined>
  upload: <JSON>(
    path: string,
    options?: BackendHttpClientSideOptions,
    file?: Blob | File[],
    fileName?: string,
  ) => Promise<JSON | undefined>
  clientFetch: <JSON>(
    input: RequestInfo,
    init?: RequestInit,
    type?: FetchType,
    refreshToken?: boolean,
  ) => Promise<JSON | undefined>
}

const backendBaseUsUrl = process.env.NEXT_PUBLIC_BACKEND_BASE_US_URL
const backendBaseUkUrl = process.env.NEXT_PUBLIC_BACKEND_BASE_UK_URL
const communityBaseUkUrl = process.env.NEXT_PUBLIC_COMMUNITY_BASE_UK_URL
const communityBaseUsUrl = process.env.NEXT_PUBLIC_COMMUNITY_BASE_US_URL

export default function useHttpRequest(): HttpMethods {
  const logout = useInnerLogout()
  const locale = useLocale()
  const defaultHeaders = useHttpHeaders()

  const [tokens, setTokens] = useUserStore(useShallow((state) => [state.tokens, state.setTokens]))

  const getBaseUrl = useCallback(
    (service?: BackendService): string | undefined => {
      if (service === undefined || service === 'hunter') {
        if (locale === 'us') {
          return backendBaseUsUrl
        }
        return backendBaseUkUrl
      }
      if (service === 'community') {
        if (locale === 'us') {
          return communityBaseUsUrl
        }
        return communityBaseUkUrl
      }
      if (service === 'web') {
        return `${basePath || '/'}/api/${locale}/`.replace(/\/\//g, '/')
      }
      return ''
    },
    [locale],
  )

  const generateHeaders = useCallback(
    (options?: BackendHttpClientSideOptions, upload = false): HeadersInit => {
      const headers: HeadersInit = { ...defaultHeaders, ...options?.headers }

      if (upload) delete headers['Content-Type' as keyof HeadersInit]

      if (options?.type === 'blob') delete headers['Accept' as keyof HeadersInit]
      else if (options?.type === 'text') headers['Accept' as keyof HeadersInit] = 'text/plain'

      return headers
    },
    [defaultHeaders],
  )

  const getRefreshedToken = useCallback(async (): Promise<string | undefined> => {
    const headers: HeadersInit = { ...defaultHeaders, 'AUTH-TOKEN': tokens[`${locale}-sonic-refresh`] }
    // eslint-disable-next-line @typescript-eslint/no-use-before-define
    const refreshToken = await httpRequest<RestResponse<{ sessionToken: string; refreshToken: string }>>(
      'GET',
      'token/refreshtoken',
      {
        headers,
        refreshToken: false,
      },
    )

    if (refreshToken?.data?.sessionToken) {
      setCookie(null, `${locale}-sonic-refresh`, refreshToken?.data?.refreshToken, {
        maxAge: 1 * 30 * 24 * 60 * 60, // Keep the cookie for 1 month
        path: '/',
        secure: true,
      })
      setCookie(null, `${locale}-sonic-user`, refreshToken?.data?.sessionToken, {
        maxAge: 1 * 60 * 60, // Keep the cookie for 1 hour
        path: '/',
        secure: true,
      })
      startTransition(() => {
        setTokens({
          [`${locale}-sonic-user`]: refreshToken?.data?.sessionToken,
          [`${locale}-sonic-refresh`]: refreshToken?.data?.refreshToken,
        })
      })
    }
    return refreshToken?.data?.sessionToken
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale])

  // TODO: Please check if it is possible to use conditional types: https://www.typescriptlang.org/docs/handbook/2/conditional-types.html
  const clientFetch = useCallback(
    async function clientFetchImplementation<JSON>(
      input: RequestInfo,
      init?: RequestInit,
      type?: FetchType,
      refreshToken = true,
    ): Promise<JSON | undefined> {
      const res = await baseFetch(input, init)

      if (res?.status === 401) {
        if (refreshToken && init?.headers) {
          const newToken = await getRefreshedToken()
          if (newToken) {
            init.headers = { ...init?.headers, 'AUTH-TOKEN': newToken }
            return clientFetch(input, init, type, false)
          }
        }
        logout()
      } else {
        switch (type) {
          case 'text':
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return res?.text() as any
          case 'blob':
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            return res?.blob() as any

          default:
            return res?.json()
        }
      }

      return undefined
    },
    [getRefreshedToken, logout],
  )

  const httpRequest = useCallback(
    function httpRequestImplementation<JSON>(
      method: 'GET' | 'POST' | 'PUT' | 'DELETE' | 'UPLOAD',
      path: string,
      options?: BackendHttpClientSideOptions,
      file?: Blob | File[],
      fileName?: string,
    ): Promise<JSON | undefined> {
      const backendBaseUrl = getBaseUrl(options?.service)
      const headers = generateHeaders(options, method === 'UPLOAD')

      const convertedBody: BodyInit | null = method === 'UPLOAD' ? new FormData() : null

      if (method === 'UPLOAD' && file && convertedBody) {
        if (file instanceof Blob) {
          convertedBody.append('file', file, fileName)
        } else {
          for (let i = 0; i < file.length; i += 1) {
            convertedBody.append('file', file[i])
          }
        }
      }
      return clientFetch<JSON>(
        backendBaseUrl + path,
        {
          method: method === 'UPLOAD' ? 'POST' : method,
          body: method === 'UPLOAD' ? convertedBody : JSON.stringify(options?.body),
          headers,
        },
        options?.type || 'json',
        options?.refreshToken !== undefined ? options?.refreshToken : true,
      )
    },
    [clientFetch, getBaseUrl, generateHeaders],
  )

  return {
    get: useCallback((...args) => httpRequest('GET', ...args), [httpRequest]),
    post: useCallback((...args) => httpRequest('POST', ...args), [httpRequest]),
    put: useCallback((...args) => httpRequest('PUT', ...args), [httpRequest]),
    remove: useCallback((...args) => httpRequest('DELETE', ...args), [httpRequest]),
    upload: useCallback((...args) => httpRequest('UPLOAD', ...args), [httpRequest]),
    clientFetch,
  }
}
