'use client'

import { JSX } from 'react'

import Script from 'next/script'

import useLocale from '@hooks/client/useLocale'
import useCookiesConsent from '@hooks/useCookiesConsent'

const appsFlyerDevKey = process.env.NEXT_PUBLIC_APPSFLYER_DEV_KEY
const appsFlyerWebKey = process.env.NEXT_PUBLIC_APPSFLYER_WEB_KEY

export default function AppFlyerScript(): JSX.Element | null {
  const locale = useLocale()
  const [areCookiesAccepted] = useCookiesConsent()

  if (!areCookiesAccepted) return <></>

  return locale === 'uk' ? (
    <Script strategy="afterInteractive" id="apps-flyer" defer>
      {`!function(t,e,n,s,a,c,i,o,p){t.AppsFlyerSdkObject=a,t.AF=t.AF||function(){(t.AF.q=t.AF.q||[]).push([Date.now()].concat(Array.prototype.slice.call(arguments)))},t.AF.id=t.AF.id||i,t.AF.plugins={},o=e.createElement(n),p=e.getElementsByTagName(n)[0],o.async=1,o.src="https://websdk.appsflyer.com?"+(c.length>0?"st="+c.split(",").sort().join(",")+"&":"")+(i.length>0?"af_id="+i:""),p.parentNode.insertBefore(o,p)}(window,document,"script",0,"AF", "pba,banners",{pba: {webAppId: "${appsFlyerDevKey}"}, banners: {key: "${appsFlyerWebKey}"}});AF('banners', 'showBanner')`}
    </Script>
  ) : null
}
