// set if we need to show the cookie popup
export default function setLocalStorageValue(flag: string, value: string): boolean {
  if (typeof window !== 'undefined') {
    try {
      window.localStorage.setItem(flag, value)
      return true
    } catch {
      return false
    }
  }
  return false
}
