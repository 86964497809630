'use client'

import { Slide, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

const ToastManager = (): JSX.Element => (
  <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar
    newestOnTop
    closeOnClick={false}
    rtl={false}
    pauseOnFocusLoss={false}
    draggable
    pauseOnHover={false}
    icon={false}
    transition={Slide}
    closeButton={false}
    draggablePercent={60}
    className="toast-container"
    role="alert"
  />
)

export default ToastManager
