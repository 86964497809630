import { useCallback } from 'react'

import type { CommunityProfile, ProfileData, RestResponse, User } from '@types'

import useHttpRequest from '@hooks/useHttpRequest'

export default function useFetchProfile(): (profileData?: User, headers?: HeadersInit) => Promise<ProfileData> {
  const { get } = useHttpRequest()

  return useCallback(
    async (profileData, headers) => {
      const user =
        profileData ??
        (await get<RestResponse<User>>('jobseeker/', headers ? { headers } : undefined).then((usr) => usr?.data))

      // TODO: refactor backend in order to have this value in a single call. We use community just for the profile image
      const community = await get<RestResponse<CommunityProfile>>(
        'user/profile',
        headers ? { headers, service: 'community' } : { service: 'community' },
      )
      return { user, community: community?.data }
    },
    [get],
  )
}
