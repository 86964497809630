import { useUserStore } from '@stores/user.store'

import { startTransition } from 'react'

import useLocale from '@hooks/client/useLocale'
import { removeAuthCookies } from '@lib/helpers/client'

export default function useInnerLogout(): () => void {
  const locale = useLocale()
  const setProfile = useUserStore((state) => state.setProfile)

  return () => {
    removeAuthCookies(locale)
    startTransition(() => {
      setProfile({})
    })
  }
}
