import { NextPageContext } from 'next'

import type { Country } from '@types'

import nookies from 'nookies'

/**
 * Check initially for the tokens of a normal user. If it returns undefined
 * check also for the guest user
 */
export default function getTokenFromCookie(
  country: Country,
  context?: Pick<NextPageContext, 'req'>,
  token?: 'refresh' | 'user',
): string {
  const cookies = nookies.get(context)

  if (!token || token === 'user') {
    const userToken = cookies[`${country}-sonic-user`]

    return userToken ?? ''
  }

  const refreshToken = cookies[`${country}-sonic-refresh`]

  return refreshToken ?? ''
}
