import { backOff } from 'exponential-backoff'

import { context, propagation } from '@opentelemetry/api'

// This function enhances the provided request initialization options
// with W3C trace context propagation headers.
function withW3CPropagation(init?: RequestInit): RequestInit {
  const output: { traceparent: string; tracestate: string } = { traceparent: '', tracestate: '' }
  propagation.inject(context.active(), output)
  const { traceparent, tracestate } = output
  return {
    ...init,
    headers: {
      ...init?.headers,
      traceparent,
      tracestate,
    },
  }
}

export const baseFetch = async (input: RequestInfo, init?: RequestInit): Promise<Response> => {
  // Exponential backoff algorithm:
  // https://en.wikipedia.org/wiki/Exponential_backoff
  return await backOff(
    async () => {
      let response: Response | undefined
      if (typeof window === 'undefined') {
        response = await fetch(input, withW3CPropagation(init))
      } else {
        response = await fetch(input, init)
      }
      return response
    },
    {
      maxDelay: 500,
      startingDelay: 50,
    },
  )
}

export const textFetch = async (input: RequestInfo, init?: RequestInit): Promise<string | undefined> => {
  return baseFetch(input, init).then((a) => a?.text())
}
