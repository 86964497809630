import { destroyCookie } from 'nookies'

export default function removeAuthCookies(locale: string): void {
  destroyCookie(null, `${locale}-sonic-refresh`, {
    path: '/',
  })
  destroyCookie(null, `${locale}-sonic-user`, {
    path: '/',
  })
  destroyCookie(null, `${locale}-soft-login`, {
    path: '/',
  })
}
