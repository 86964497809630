'use client'

import { FC, useEffect, useState } from 'react'

import { useParams } from 'next/navigation'

import { basePath } from '@lib/constants'

const scriptId = 'spec-rules'
const SpeculationRules: FC = () => {
  const params = useParams()
  const [loaded, setLoaded] = useState(false)
  useEffect(() => {
    if (loaded) return
    if (document.getElementById(scriptId)) return
    if (HTMLScriptElement.supports && HTMLScriptElement.supports('speculationrules')) {
      const specScript = document.createElement('script')
      specScript.type = 'speculationrules'
      specScript.id = scriptId
      const specRules = {
        prerender: [
          {
            source: 'document',
            where: {
              or: [
                { href_matches: `${basePath}/${params.locale}` },
                { href_matches: `${basePath}/${params.locale}/jobs*` },
                { href_matches: `${basePath}/${params.locale}/search*` },
                { href_matches: `${basePath}/${params.locale}/(blogs|legal|login|privacy|welcome-video)/` },
              ],
            },
            eagerness: 'moderate',
          },
        ],
      }
      specScript.textContent = JSON.stringify(specRules)
      document.body.append(specScript)
      setLoaded(true)
    }
  }, [params.locale, loaded])
  return <></>
}

export default SpeculationRules
