import { useUserStore } from '@stores/user.store'

import { useMemo } from 'react'

import type { Country } from '@types'

import { getTokenFromCookie } from '@lib/helpers/client'

import useLocale from './client/useLocale'

const apiVersion = process.env.NEXT_PUBLIC_BACKEND_API_VERSION
const chatVersion = process.env.NEXT_PUBLIC_BACKEND_CHAT_VERSION

const useHttpHeaders = (): HeadersInit => {
  const locale = useLocale()
  const tokens = useUserStore((state) => state.tokens)
  return useMemo(
    () => ({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      'AUTH-TOKEN': tokens[`${locale}-sonic-user`] || getTokenFromCookie(locale as Country) || '',
      'CLIENT-ID': 'WEB',
      'SONIC-API-VERSION': apiVersion || '21',
      'CHAT-VERSION': chatVersion || '18',
    }),
    [tokens, locale],
  )
}

export default useHttpHeaders
