'use client'

import { useHistoryStore } from '@stores/history.store'

import { useEffect } from 'react'

import { usePathname, useSearchParams } from 'next/navigation'

import { basePath } from '@lib/constants'

export default function UpdateHistory(): JSX.Element {
  const setHistory = useHistoryStore((state) => state.setHistory)
  const pathname = usePathname()
  const searchParams = useSearchParams()

  useEffect(() => {
    const query = searchParams?.toString() || ''
    const path = basePath.length > 0 ? pathname?.replace(basePath, '') : pathname

    setHistory(`${path}${query?.length > 0 ? '?' : ''}${query}`)
  }, [pathname, searchParams, setHistory])

  return <></>
}
