/* eslint-disable default-case */
import type { UserSource } from '@types'

const sources: UserSource[] = [
  'google',
  'facebook',
  'instagram',
  'appcast',
  'facebook',
  'resume-library',
  'texas.tarta',
  'linkedin',
  'jooble',
  'snagajob',
]

export default function getUserSource(): UserSource {
  if (typeof window !== 'undefined') {
    const url = new URL(window.location.href)

    const campaign = url.searchParams.get('utm_campaign')
    const source = url.searchParams.get('utm_source')
    const login = url.searchParams.get('login')

    if (login) {
      return 'soft_login'
    }

    // Switch in order to make it extensible
    switch (campaign) {
      case 'google_jobs_apply':
        return 'G4J'
    }

    // Switch in order to make it extensible
    switch (source) {
      case 'linkedin':
        return 'linkedin'
      case 'Sonic_Emails':
        return 'sonic_emails'
    }

    if (url.searchParams.get('trk') === 'bingforjobs') return 'B4J'

    try {
      const referrer = new URL(document.referrer)
      const referrerHost = referrer.host
      const referrerSource = referrer.searchParams.get('utm_source')

      if (referrerHost.includes('t.co')) return 'twitter'
      if (referrerSource === 'Sonic_Emails') return 'sonic_emails'

      // eslint-disable-next-line consistent-return
      sources.forEach((src) => {
        if (referrerHost.includes(src)) return src
      })

      // eslint-disable-next-line no-empty
    } catch {}
  }

  return ''
}
