'use client'

import { useUserStore } from '@stores/user.store'

import { startTransition, useEffect } from 'react'

import type { ProfileData, RestResponse } from '@types'

import nookies, { setCookie } from 'nookies'
import { useShallow } from 'zustand/react/shallow'

import useLocale from '@hooks/client/useLocale'
import useHttpHeaders from '@hooks/useHttpHeaders'
import useHttpRequest from '@hooks/useHttpRequest'
import { miscFailedToSetUserSourceEvent } from '@lib/analytics-events'
import { getUserSource } from '@lib/helpers/client'

import useFetchProfile from './useFetchProfile'
import useGetUserID from './useGetUserId'
import useIsLoggedIn from './useIsLoggedIn'

export default function useInitialiseUser(): void {
  const locale = useLocale()
  const { isLoggedIn } = useIsLoggedIn()
  const { get } = useHttpRequest()
  // const isJobUIExperiment = useJobUIExperiment()
  const userId = useGetUserID()
  const fetchProfile = useFetchProfile()
  const cookies = nookies.get()
  const [profile, setProfile, setTokens, tokens, setSource] = useUserStore(
    useShallow((state) => [state.profile, state.setProfile, state.setTokens, state.tokens, state.setSource]),
  )
  const defaultHeaders = useHttpHeaders()

  useEffect(() => {
    const deferredSetProfile = (prof: ProfileData): void => {
      startTransition(() => {
        setProfile(prof)
      })
    }
    ;(async (): Promise<void> => {
      if (!profile.user?.id) {
        if (cookies[`${locale}-sonic-refresh`] && !cookies[`${locale}-sonic-user`]) {
          const headers = { ...defaultHeaders, 'AUTH-TOKEN': cookies[`${locale}-sonic-refresh`] }
          const token = await get<RestResponse<{ sessionToken: string; refreshToken: string }>>('token/refreshtoken', {
            headers,
            refreshToken: false,
          })

          if (token?.data?.sessionToken) {
            setCookie(null, `${locale}-sonic-refresh`, token?.data?.refreshToken, {
              maxAge: 1 * 30 * 24 * 60 * 60, // Keep the cookie for 1 month
              path: '/',
              secure: true,
            })
            setCookie(null, `${locale}-sonic-user`, token?.data?.sessionToken, {
              maxAge: 1 * 60 * 60, // Keep the cookie for 1 hour
              path: '/',
              secure: true,
            })

            if (token.data.sessionToken) {
              headers['AUTH-TOKEN'] = token.data.sessionToken
              const data = await fetchProfile(undefined, headers)
              deferredSetProfile({ ...data })
            }
          }
          return
        }
        if (cookies[`${locale}-sonic-user`]) {
          const data = await fetchProfile()
          deferredSetProfile({ ...data })
        }
      }
    })()
  }, [fetchProfile, profile, cookies, locale, setProfile, get, defaultHeaders])

  useEffect(() => {
    if (isLoggedIn) {
      window.mixpanel?.register?.({
        jobSeekerId: userId,
        distinct_id: userId,
        // isJobCardExperimentNov: isJobUIExperiment,
      })
      window?.gtag?.('set', 'user_properties', {
        distinct_id: userId,
        jobSeekerId: userId,
        // isJobCardExperimentNov: isJobUIExperiment,
      })
    } else {
      window?.mixpanel?.register?.({
        distinct_id: userId,
        // isJobCardExperimentNov: isJobUIExperiment,
      })
      window?.gtag?.('set', 'user_properties', {
        distinct_id: userId,
        // isJobCardExperimentNov: isJobUIExperiment,
      })
    }
  }, [isLoggedIn, userId])

  useEffect(() => {
    const deferredSetTokens = (toks: Record<string, string>): void => {
      startTransition(() => {
        setTokens(toks)
      })
    }

    const localeTokens = Object.entries(tokens).filter((el) =>
      [`${locale}-sonic-user`, `${locale}-sonic-refresh`].includes(el[0]),
    )
    if (localeTokens.length === 0) {
      deferredSetTokens({
        [`${locale}-sonic-user`]: cookies[`${locale}-sonic-user`],
        [`${locale}-sonic-refresh`]: cookies[`${locale}-sonic-refresh`],
      })
    }
  }, [locale, isLoggedIn, tokens, cookies, setTokens])

  useEffect(() => {
    const source = getUserSource()

    if (source === '') {
      miscFailedToSetUserSourceEvent(document.referrer)
    }

    setSource(source)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setSource])
}
